.draft-claims {
  margin-top: 40px; }
  @media (min-width: 992px) {
    .draft-claims {
      margin-top: 0; } }
  .draft-claims-title {
    color: #27251f;
    font-size: 24px !important;
    font-size: 1.5rem !important;
    line-height: 34px;
    line-height: 2.125rem;
    font-weight: 600 !important;
    margin-top: 15px !important;
    padding-top: 20px !important; }
  .draft-claims .sub-title {
    color: #27251f !important;
    font-size: 16px !important;
    font-size: 1rem !important;
    margin-top: 5px; }
  .draft-claims .support-text {
    font-size: 16px !important;
    font-size: 1rem !important; }
    .draft-claims .support-text label {
      font-size: 16px !important;
      font-size: 1rem !important;
      line-height: 20px;
      line-height: 1.25rem; }
    .draft-claims .support-text p {
      font-size: 16px !important;
      font-size: 1rem !important; }
  .draft-claims .actions {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .draft-claims .actions .btn {
      display: block;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      margin-bottom: 10px;
      padding: 10px !important;
      text-align: center; }
      @media (min-width: 992px) {
        .draft-claims .actions .btn {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          font-size: 14px;
          font-size: 0.875rem;
          margin-bottom: 0; } }
      .draft-claims .actions .btn:last-of-type {
        margin-bottom: 0; }
