.preferences-section {
  padding: 15px; }
  @media (min-width: 992px) {
    .preferences-section {
      padding: 20px; } }

.preferences-table .table-header {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 600; }

.preferences-table tbody > tr > td {
  border-top: 0;
  padding: 20px; }

.preferences-table-head {
  border: 0 !important; }

.preferences-table-item {
  border: 0 !important;
  padding: 16px 20px; }
  .preferences-table-item:nth-child(even) {
    background-color: #f8f8f8; }
  .preferences-table-item .table-responsive-stack {
    background-color: transparent !important;
    margin-bottom: 0 !important; }
  .preferences-table-item .delivery,
  .preferences-table-item label {
    font-size: 14px !important;
    font-size: 0.875rem !important; }
    @media (min-width: 992px) {
      .preferences-table-item .delivery,
      .preferences-table-item label {
        font-size: 16px !important;
        font-size: 1rem !important; } }

.preference-header {
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem; }

@media (max-width: 991.98px) {
  .pref-notif-pref {
    margin-top: 15px; } }

.preference-email {
  font-weight: 700; }
  @media (max-width: 991.98px) {
    .preference-email {
      position: absolute;
      left: 0; } }

.preferences-policy-number {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    .preferences-policy-number {
      font-size: 16px;
      font-size: 1rem; } }

.preferences-policy-description {
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    .preferences-policy-description {
      font-size: 16px;
      font-size: 1rem; } }

.preferences-update-email {
  margin-top: 15px; }

.preferences-panel .panel-heading {
  border-bottom: 1px solid #d7d8d7 !important; }

.preferences-panel .collapsed .panel-heading {
  border-bottom-color: transparent !important; }

.preferences-panel .table-responsive-stack .head {
  padding-bottom: 20px; }

.preferences-panel {
  padding-bottom: 0; }
  .preferences-panel .radio-inline {
    font-size: 16px !important;
    font-size: 1rem !important;
    margin-bottom: 3px; }
  .preferences-panel .form-helper-text {
    padding-left: 15px;
    padding-right: 15px; }
  .preferences-panel .form.row {
    font-size: 16px !important;
    font-size: 1rem !important;
    padding-top: 16px;
    padding-bottom: 16px; }
  .preferences-panel .last-modified {
    margin-top: 10px;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem; }

.preferences-accept-terms {
  font-weight: 600;
  font-size: 16px;
  font-size: 1rem; }

@media (max-width: 991.98px) {
  .preferences-panel .table-responsive-stack,
  .preferences-panel .form-group,
  .preferences-panel .radio-inline {
    margin-bottom: 0; }
  .preferences-panel .delivery {
    margin-top: 15px; }
  .preferences-form-actions > div {
    width: 100%; }
  .preferences-form-actions .btn {
    width: 100%; }
  .preferences-table {
    margin-bottom: 15px !important; } }
