.breadcrumb {
  margin: 20px 15px 20px 0;
  padding: 0; }
  @media (min-width: 992px) {
    .breadcrumb {
      margin: 30px 15px 12px 15px; } }
  .breadcrumb-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumb-items-item {
      color: #3c3c3c;
      display: none;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      @media (min-width: 992px) {
        .breadcrumb-items-item {
          display: block; } }
      .breadcrumb-items-item:nth-last-child(2) {
        display: block; }
        .breadcrumb-items-item:nth-last-child(2)::before {
          content: '<';
          display: inline-block;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          padding-left: 5px;
          padding-right: 5px; }
          @media (min-width: 992px) {
            .breadcrumb-items-item:nth-last-child(2)::before {
              display: none; } }
      @media (min-width: 992px) {
        .breadcrumb-items-item::after {
          content: '>';
          display: inline-block;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          padding-left: 5px;
          padding-right: 5px; } }
      .breadcrumb-items-item:last-child::after {
        display: none; }
      .breadcrumb-items-item a {
        color: #3c3c3c;
        text-decoration: underline; }

@media (max-width: 991.98px) {
  .breadcrumb-container + .site-content {
    margin-top: 0;
    padding-top: 0; } }
