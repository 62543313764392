.img-theme {
  background-image: none;
  background-color: #f8f8f8; }

@media (min-width: 992px) {
  .login-panel {
    padding: 40px; } }

.login-apps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .login-apps {
      margin-top: 40px; } }
  .login-apps a {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    width: auto;
    max-width: auto;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .login-apps a {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        width: auto;
        max-width: auto;
        margin-bottom: 0; } }

@media (min-width: 992px) {
  .login-left-col {
    border-right: 1px solid #d7d8d7 !important; } }

.login-left-col button {
  margin-top: 20px; }

.login-right-col {
  margin-top: 40px; }
  @media (max-width: 991.98px) {
    .login-right-col {
      border-top: 1px solid #d7d8d7;
      margin-top: 40px;
      padding-top: 40px; } }
  @media (min-width: 992px) {
    .login-right-col {
      margin-top: 0; } }
  .login-right-col .caption {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem; }
  .login-right-col .semi-bold {
    font-family: "museo-slab", Serif;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 0; }
  .login-right-col .quick-pay {
    margin-top: 40px; }
    @media (min-width: 992px) {
      .login-right-col .quick-pay {
        bottom: 0;
        position: absolute; } }
