.messaging-container {
  background: #FFFFFF; }
  @media (max-width: 991.98px) {
    .messaging-container {
      margin-left: -15px;
      padding: 15px;
      width: -webkit-calc(100% + 30px);
      width: calc(100% + 30px); } }
  @media (min-width: 992px) {
    .messaging-container {
      margin-top: 100px;
      padding: 60px 95px; } }
  .messaging-container .input-panel {
    padding: 0; }
    .messaging-container .input-panel label {
      font-size: 18px;
      font-size: 1.125rem; }
    .messaging-container .input-panel input[type=text],
    .messaging-container .input-panel select,
    .messaging-container .input-panel textarea {
      background-color: #f8f8f8 !important;
      border-radius: 4px 4px 0 0 !important;
      border-bottom: 2px solid #909090 !important;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      width: 100% !important; }
    .messaging-container .input-panel textarea {
      border-radius: 4px !important;
      border: 2px solid #909090 !important;
      height: 250px !important; }
    .messaging-container .input-panel button {
      text-align: center;
      width: 100%; }
    .messaging-container .input-panel select {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-DownArrow%3C/title%3E%3Cg id='icon-DownArrow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Container-24px' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M20.5812309,9.28590475 C20.9386353,8.95124925 21.0812795,8.45715689 20.9545422,7.99282492 C20.8278049,7.52849295 20.4513216,7.16586015 19.9692548,7.04378544 C19.4871879,6.92171073 18.974224,7.0591071 18.6267866,7.40336289 L12,13.7863554 L5.37321157,7.40336171 C5.02577382,7.05910685 4.5128104,6.92171116 4.03074422,7.04378592 C3.54867804,7.16586068 3.17219527,7.52849293 3.04545794,7.99282427 C2.91872062,8.4571556 3.06136405,8.9512475 3.41876751,9.28590328 L11.0227778,16.6101679 C11.5625143,17.129944 12.4374856,17.129944 12.977222,16.610168 L20.5812309,9.28590475 Z' id='icon-DownArrow-Dark-Grey' fill='%2327251F'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      background-size: 25px;
      padding-right: 50px !important;
      width: 100%; }
      .messaging-container .input-panel select:active, .messaging-container .input-panel select:focus {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;%7D.cls-2%7Bfill:%2327251f;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cg id='icon-DownArrow'%3E%3Crect id='Container-24px' class='cls-1' width='24' height='24'/%3E%3Cpath id='icon-DownArrow-Dark-Grey' class='cls-2' d='M13,7.39a1.43,1.43,0,0,0-2,0l-7.6,7.32A1.3,1.3,0,0,0,3.05,16,1.36,1.36,0,0,0,4,17a1.43,1.43,0,0,0,1.34-.36L12,10.21l6.63,6.39A1.43,1.43,0,0,0,20,17,1.36,1.36,0,0,0,21,16a1.3,1.3,0,0,0-.37-1.3Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }
    .messaging-container .input-panel .checkbox span {
      color: #909090;
      font-size: 24px;
      font-size: 1.5rem; }
    .messaging-container .input-panel .checkbox label {
      padding-left: 41px; }
    .messaging-container .input-panel .checkbox input[type='checkbox']:checked + span::after,
    .messaging-container .input-panel .checkbox input[type='checkbox']:checked + input[type='hidden'] + span::after {
      color: #DA291C; }
    .messaging-container .input-panel .radio-inline label {
      padding-left: 10px; }
    .messaging-container .input-panel .radio-inline input[type="radio"] {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #FFFFFF;
      margin: 0;
      width: 20px;
      height: 20px;
      border: 3px solid #909090;
      border-radius: 50%;
      display: -ms-grid;
      display: grid;
      place-content: center;
      opacity: 1;
      -webkit-transform: translateY(3px);
          -ms-transform: translateY(3px);
              transform: translateY(3px); }
    .messaging-container .input-panel .radio-inline input[type="radio"]::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      -webkit-transform: scale(0);
          -ms-transform: scale(0);
              transform: scale(0);
      -webkit-transition: 120ms -webkit-transform ease-in-out;
      transition: 120ms -webkit-transform ease-in-out;
      transition: 120ms transform ease-in-out;
      transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
      -webkit-box-shadow: inset 12px 12px #DA291C;
              box-shadow: inset 12px 12px #DA291C; }
    .messaging-container .input-panel .radio-inline input[type="radio"]:checked::before {
      -webkit-transform: scale(1);
          -ms-transform: scale(1);
              transform: scale(1); }
  .messaging-container .form-group.has-success::after,
  .messaging-container .form-group.has-error::after {
    display: none; }

.messaging-intro {
  text-align: left; }
