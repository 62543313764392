main {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  outline: 0 !important; }
  @media (min-width: 768px) {
    main > *:last-child {
      padding-bottom: 120px; } }

.overflow-hidden {
  overflow-x: hidden; }

strong, b, .bold {
  font-weight: bold; }

.btn:active {
  color: #FFFFFF !important;
  background: #27251f !important;
  outline: 0 !important; }

main, footer {
  outline: 0; }
  main a, footer a {
    text-decoration: underline; }

main .btn:not(.dropdown-toggle) {
  min-width: 0; }

p a, ul a {
  font-weight: 600; }

.headings-bold h3 {
  font-weight: bold; }

.container, .container-fluid {
  padding-left: 15px;
  padding-right: 15px; }

.bg-secondary .btn {
  background: #0075c9;
  color: #DA291C; }
  .bg-secondary .btn:hover, .bg-secondary .btn:focus, .bg-secondary .btn:active {
    background: #FFFFFF;
    color: #DA291C; }

.bg-primary .btn {
  background: #DA291C;
  color: #FFFFFF; }
  .bg-primary .btn:hover, .bg-primary .btn:focus, .bg-primary .btn:active {
    background: #FFFFFF;
    color: #DA291C; }

.section {
  padding: 60px 0; }
  @media (min-width: 768px) {
    .section {
      padding: 100px 0; } }
  .section--small {
    padding: 5px 0 15px; }
    @media (min-width: 768px) {
      .section--small {
        padding: 5px 0 15px; } }
  .section.no-padding-top {
    padding-top: 0; }
  .section.no-padding-bottom {
    padding-bottom: 0; }

.section-header {
  text-align: center;
  max-width: 960px;
  margin: 0 auto 30px; }
  .section-header.align-left {
    text-align: left;
    max-width: none; }
  @media (min-width: 768px) {
    .section-header {
      margin: 0 auto 40px;
      font-size: 20px;
      line-height: 30px; } }
  .section-header h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 44px; }
    @media (min-width: 768px) {
      .section-header h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 52px; } }
  .section-header .description, .section-header .description p {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 32px;
    line-height: 2rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .section-header .description, .section-header .description p {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 34px;
        line-height: 2.125rem; } }

.alt-sections > section:nth-child(odd) {
  background-color: #FFFFFF;
  padding-top: 40px !important; }
  @media (min-width: 768px) {
    .alt-sections > section:nth-child(odd) {
      padding-top: 60px !important; } }

.alt-sections > section:nth-child(even) {
  background-color: #f8f8f8;
  padding-top: 40px !important; }
  @media (min-width: 768px) {
    .alt-sections > section:nth-child(even) {
      padding-top: 60px !important; } }

.section--column-border > .container > .row > div:first-child {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .section--column-border > .container > .row > div:first-child {
      padding-right: 30px;
      border-right: solid 1px #d7d8d7;
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .section--column-border > .container > .row > div:first-child {
      padding-right: 60px; } }

@media (min-width: 768px) {
  .section--column-border > .container > .row > div:last-child {
    padding-left: 60px; } }

@media (min-width: 992px) {
  .section--column-border > .container > .row > div:last-child {
    padding-left: 120px; } }

.section.bg-white + .bg-white {
  padding-top: 0; }

@media (min-width: 768px) {
  .section.detail {
    padding: 50px 0; } }

.section.product-callout .section-header {
  margin-bottom: 52px; }
  @media (min-width: 768px) {
    .section.product-callout .section-header {
      margin-bottom: 50px; } }

.location-map {
  height: 300px;
  width: 100%; }
  @media (min-width: 768px) {
    .location-map {
      height: 400px;
      width: 100%; } }

@media (min-width: 768px) {
  .col-md-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .section--request-quote {
    padding: 70px 0; } }

@media (max-width: 767.98px) {
  .col-md-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .section--request-quote {
    padding: 40px 0; } }

.site-footer {
  background-color: #27251f; }
  @media (min-width: 992px) {
    .site-footer {
      height: 78px; } }
  .site-footer .horizontal-links a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 18px;
    line-height: 1.125rem;
    font-weight: 600;
    text-decoration: none; }

@media (min-width: 992px) {
  .site-header {
    border-bottom: 1px solid #C9C9C9;
    height: 76px; } }

@media (min-width: 992px) {
  .site-header .container {
    max-width: 1300px; } }

@media (min-width: 992px) {
  .site-header #site-logo {
    margin-top: 20px;
    width: 265px; } }

.site-header [class*='mobile-menu-'] {
  float: right;
  font-size: 24px;
  font-size: 1.5rem;
  padding: 15px; }

@media (min-width: 992px) {
  .site-header .navbar-left {
    margin-left: 70px;
    margin-top: 12px; } }

@media (min-width: 992px) {
  .site-header .navbar-right {
    margin-top: 12px; } }

.site-header .navbar-nav li a {
  color: #27251f;
  font-weight: normal !important; }
  @media (min-width: 992px) {
    .site-header .navbar-nav li a {
      font-size: 18px;
      font-size: 1.125rem; } }

@media (min-width: 992px) {
  .site-header {
    /*
        .navbar-right {
            .dropdown.open a {
                background-color: $white !important;
            }

            .dropdown-menu {
                @include box-shadow;
                border: 0;
                border-radius: 0 0 10px 10px !important;
                margin-top: 14px;
                padding: 15px 30px;
                width: 260px;

                a {
                    padding: 10px 0;
                }
            }
        }
        */ }
    .site-header .navbar-left .dropdown a,
    .site-header .navbar-left a,
    .site-header .navbar-right .dropdown a,
    .site-header .navbar-right a {
      font-weight: normal; }
    .site-header .navbar-left .dropdown.open a,
    .site-header .navbar-right .dropdown.open a {
      background-color: #FFFFFF !important; }
    .site-header .navbar-left .dropdown-menu,
    .site-header .navbar-right .dropdown-menu {
      -webkit-box-shadow: none;
              box-shadow: none;
      border: 1px solid #C9C9C9;
      border-top: 0;
      margin-top: 14px;
      min-width: 340px;
      padding: 24px 36px;
      width: auto; }
      .site-header .navbar-left .dropdown-menu a:hover,
      .site-header .navbar-right .dropdown-menu a:hover {
        background-color: transparent; }
      .site-header .navbar-left .dropdown-menu a::after,
      .site-header .navbar-right .dropdown-menu a::after {
        display: none !important; }
    .site-header .navbar-left .dropdown.open .dropdown-menu,
    .site-header .navbar-right .dropdown.open .dropdown-menu {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: (min-content)[7];
          grid-template-rows: repeat(7, -webkit-min-content);
          grid-template-rows: repeat(7, min-content);
      grid-auto-flow: column; }
    .site-header .navbar-left .dropdown-label,
    .site-header .navbar-right .dropdown-label {
      color: #707070;
      display: block;
      font-size: 16px;
      font-size: 1rem; }
    .site-header .navbar-left .fa-chevron-down,
    .site-header .navbar-right .fa-chevron-down {
      font-size: 14px;
      font-size: 0.875rem;
      margin-left: 3px;
      position: relative;
      top: -3px; }
    .site-header .navbar-left .dropdown.open a::after {
      background-color: #DA291C;
      border-radius: 3px;
      content: '';
      display: block;
      height: 5px;
      margin-top: 4px;
      width: 100%; }
    .site-header .navbar-left .dropdown-menu {
      margin-top: 5px; } }

.mobile-menu .navbar {
  padding: 20px; }
  .mobile-menu .navbar-nav {
    margin-left: -20px;
    margin-right: -20px; }
    .mobile-menu .navbar-nav > li {
      border-bottom: 1px solid #C9C9C9;
      padding: 10px 0; }
      .mobile-menu .navbar-nav > li:hover {
        background-color: transparent !important; }
      .mobile-menu .navbar-nav > li a {
        background-color: transparent !important; }
        .mobile-menu .navbar-nav > li a:hover {
          background-color: transparent !important; }
      .mobile-menu .navbar-nav > li i {
        float: right; }
      .mobile-menu .navbar-nav > li.open i {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
      .mobile-menu .navbar-nav > li .dropdown-menu {
        background-color: #f6f6f6 !important;
        margin: 0 0 -10px 0;
        padding: 10px 0; }
        .mobile-menu .navbar-nav > li .dropdown-menu li {
          padding: 0; }
          .mobile-menu .navbar-nav > li .dropdown-menu li:hover {
            background-color: transparent !important; }
          .mobile-menu .navbar-nav > li .dropdown-menu li a {
            padding: 10px 20px !important; }
            .mobile-menu .navbar-nav > li .dropdown-menu li a:hover {
              background-color: transparent !important; }

.mobile-menu .dropdown-label {
  display: block;
  font-size: 0.875rem;
  color: #3c3c3c; }
