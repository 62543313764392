.card-agent {
  margin-top: 32px; }
  .card-agent-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-agent-details-avatar {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100px;
              flex: 0 0 100px; }
      .card-agent-details-avatar img {
        -o-object-fit: cover;
           object-fit: cover; }
    .card-agent-details-body {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      padding-left: 16px; }
  .card-agent-name {
    font-family: "museo-slab", Serif;
    font-weight: 800;
    margin-bottom: 16px; }
  .card-agent-contact {
    margin-top: 16px; }
    .card-agent-contact a {
      color: #27251f; }
    .card-agent-contact-email {
      padding-bottom: 8px; }
  .card-agent-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 24px; }
    .card-agent-actions-item a {
      text-decoration: underline; }
