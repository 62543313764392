.policy-highlight-container {
  background-color: #0075c9;
  padding-bottom: 5px;
  padding-top: 5px; }
  @media (min-width: 992px) {
    .policy-highlight-container .container {
      max-width: 1300px; } }
  .policy-highlight-container li a {
    color: #FFFFFF !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .policy-highlight-container .dropdown .dropdown-toggle::after {
    border: 0 !important;
    font-size: 30px;
    font-size: 1.875rem;
    position: relative;
    top: 10px; }
  .policy-highlight-container .policy-number {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 34px;
    line-height: 2.125rem; }
  .policy-highlight-container .policy-description {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem; }
