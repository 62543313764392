.material-card {
  background-color: #FFFFFF;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  border-top: 0 !important;
  overflow: hidden; }
  .material-card--padded {
    padding: 20px; }
    @media (min-width: 992px) {
      .material-card--padded {
        padding: 40px; } }
    .material-card--padded .title {
      font-family: "museo-slab", Serif;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      line-height: 3.25rem; }
    .material-card--padded .material-card-actions {
      padding-bottom: 0 !important; }
  .material-card.alert-banner {
    padding: 20px; }
    @media (min-width: 992px) {
      .material-card.alert-banner {
        margin-bottom: 70px;
        margin-top: 40px;
        padding: 36px; } }
    .material-card.alert-banner img {
      position: relative; }
  .material-card-intro {
    padding: 35px; }
    .material-card-intro .support-text {
      padding: 0; }
  .material-card .table-responsive-stack {
    border-radius: 10px; }
  .material-card-title {
    color: #DA291C !important;
    font-family: "museo-slab", Serif !important;
    font-size: 24px !important;
    font-size: 1.5rem !important;
    line-height: 34px;
    line-height: 2.125rem;
    font-weight: 700 !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important; }
    .material-card-title .help-links {
      font-size: 18px;
      font-size: 1.125rem;
      font-family: "Open Sans", Arial, Sans-Serif;
      font-weight: 600; }
    .material-card-title i {
      color: #27251f; }
    @media (min-width: 992px) {
      .material-card-title img {
        margin-left: 7px; } }
  .material-card-message {
    font-size: 16px !important;
    font-size: 1rem !important; }
  @media (min-width: 992px) {
    .material-card-content-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; } }
  @media (min-width: 992px) {
    .material-card-content-media {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 111px;
              flex: 0 0 111px; } }
  .material-card-content-body {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media (min-width: 992px) {
      .material-card-content-body {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 calc(100% - 111px);
                flex: 0 0 -webkit-calc(100% - 111px);
                flex: 0 0 calc(100% - 111px);
        padding-left: 30px; } }
  .material-card-content-title {
    color: #27251f;
    font-family: "museo-slab", Serif;
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 52px;
    line-height: 3.25rem; }
  .material-card hr {
    border-color: #d7d8d7 !important;
    margin: 0; }
  .material-card-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    padding: 20px !important; }
    @media (min-width: 992px) {
      .material-card-actions {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
    .material-card-actions .btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      float: none;
      flex: 0 0 auto;
      padding: 15px 30px !important;
      width: auto; }
      .material-card-actions .btn--primary {
        color: #FFFFFF;
        background: #DA291C;
        border-color: #DA291C; }
        @media (min-width: 992px) {
          .material-card-actions .btn--primary:active, .material-card-actions .btn--primary:focus, .material-card-actions .btn--primary:hover {
            color: #FFFFFF;
            background: #be170a;
            border-color: #be170a; } }
      .material-card-actions .btn--link {
        background: transparent !important;
        color: #0075c9 !important;
        padding: 15px 0 !important; }
        .material-card-actions .btn--link:hover {
          color: #0d77cb; }
  .material-card-note,
  .material-card-note p {
    color: #707070;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem;
    font-weight: 400; }
  .material-card .status-message.wide:after {
    font-size: 40px;
    font-size: 2.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
