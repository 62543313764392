.policy-documents {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .policy-documents {
      margin-top: 80px; } }
  .policy-documents .panel-group {
    background-color: #FFFFFF;
    border-radius: 10px !important;
    -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
            box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
    border-top: 0 !important;
    padding-bottom: 10px; }
    @media (max-width: 991.98px) {
      .policy-documents .panel-group {
        width: 100%; } }
    .policy-documents .panel-group-header {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      color: #DA291C;
      font-family: "museo-slab", Serif;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 34px;
      line-height: 2.125rem;
      font-weight: 700;
      padding-top: 15px;
      padding-bottom: 15px; }
      @media (max-width: 991.98px) {
        .policy-documents .panel-group-header {
          margin: auto;
          width: 100%; } }
    .policy-documents .panel-group .panel {
      border-bottom: 1px solid #d7d8d7 !important; }
      .policy-documents .panel-group .panel:last-of-type {
        border-bottom: 0 !important; }
      @media (max-width: 991.98px) {
        .policy-documents .panel-group .panel {
          margin: auto;
          width: 100%; } }
  .policy-documents .panel-group .panel + .panel,
  .policy-documents .panel-group .tile + .panel,
  .policy-documents .panel-group .panel + .tile,
  .policy-documents .panel-group .tile + .tile {
    margin-top: 0; }
  .policy-documents .panel-heading {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    font-weight: 600; }
    .policy-documents .panel-heading::after {
      font-size: 28px;
      font-size: 1.75rem; }
    @media (max-width: 991.98px) {
      .policy-documents .panel-heading {
        margin: auto;
        width: 100%; } }
  .policy-documents .list-group-item {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    padding: 5px 10px !important; }
    .policy-documents .list-group-item i {
      color: #DA291C; }
  .policy-documents .list-group a {
    color: #0075c9 !important;
    font-weight: 600; }

.policy-billing-documents {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .policy-billing-documents {
      margin-top: 80px; } }
  .policy-billing-documents h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 52px;
    line-height: 3.25rem;
    font-weight: 700; }
