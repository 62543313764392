.accordion {
  height: auto;
  margin-bottom: 15px; }

.accordion-wrapper {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.accordion-title {
  cursor: pointer;
  padding: 40px 15px;
  margin: 0 !important;
  position: relative;
  width: 100%; }
  @media (min-width: 992px) {
    .accordion-title {
      padding: 48px 40px; } }
  .accordion-title.active {
    padding-bottom: 20px; }
  .accordion-title::after {
    font-size: 26px;
    font-size: 1.625rem;
    color: #3c3c3c;
    content: '\F078';
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    position: absolute;
    pointer-events: none;
    top: 40px;
    right: 40px;
    z-index: 1; }
    @media (min-width: 992px) {
      .accordion-title::after {
        top: 48px; } }
  .accordion-title.active::after {
    content: '\F077'; }

.accordion-content {
  height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in; }
  .accordion-content-wrap {
    padding: 30px; }
    .accordion-content-wrap p:last-child {
      margin-bottom: 0; }
  .accordion-content.active {
    height: auto;
    visibility: visible;
    opacity: 1; }
