#changePasswordForm {
  margin-top: 20px;
  /*
    @include media-breakpoint-up(lg) {
        //padding: 40px;
    }

    .form-actions {
        margin-top: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        .btn {
            margin-bottom: 0 !important;
        }
    }
        */ }
  @media (min-width: 992px) {
    #changePasswordForm {
      margin-left: 15px;
      margin-right: 15px;
      width: -webkit-calc(100% - 30px);
      width: calc(100% - 30px); } }
