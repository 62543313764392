.claim-tile-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

.claim-tile {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  margin: 15px 0;
  padding: 15px 0;
  text-align: left; }
  @media (min-width: 992px) {
    .claim-tile {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%; } }
  .claim-tile .tile-body {
    padding: 0;
    margin: 0; }
  .claim-tile .tile-title {
    font-weight: 600; }
  .claim-tile-separator {
    background-color: #d7d8d7;
    height: 1px;
    position: relative;
    width: 100%; }

.selected-tile {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 14px 40px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 40px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  margin: 15px 0;
  padding: 20px; }
  @media (min-width: 992px) {
    .selected-tile {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin: 30px 0 15px 0;
      margin-right: 50%; } }
  .selected-tile .tile-body {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%; }
    .selected-tile .tile-body .name {
      font-weight: 600; }
  .selected-tile .actions {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    margin: 0;
    padding: 0;
    text-align: right; }
  .selected-tile button {
    background-color: transparent;
    border: 0;
    color: #0075c9;
    margin-left: 10px;
    min-width: 0;
    padding: 0; }
    .selected-tile button i {
      font-size: 18px;
      font-size: 1.125rem; }
    .selected-tile button.primary {
      color: #DA291C; }

.claim-tile-list-wrapper {
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;
  padding: 0;
  width: 100%; }

.claim-tile-list .selected-tile {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0; }
  .claim-tile-list .selected-tile .tile-body .name {
    font-weight: normal; }

.claim-tile-list .form-group {
  margin: 0;
  padding: 0; }

.claim-tile-list-item {
  border-bottom: 1px solid #d7d8d7;
  font-size: 16px;
  font-size: 1rem;
  padding: 15px 20px; }
  .claim-tile-list-item:last-child {
    border-bottom: 0; }

.claim-tile-list-label {
  font-weight: 600; }

.claim-tile-list-value a {
  font-weight: 600; }
