.card {
  background-color: #FFFFFF;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  border-top: 0 !important;
  margin-bottom: 32px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px); }
  @media (min-width: 992px) {
    .card {
      margin-left: 0;
      margin-right: 0;
      width: 100%; } }
  .card--primary {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    padding: 48px 15px; }
    @media (min-width: 992px) {
      .card--primary {
        padding: 48px 40px;
        width: 100%; } }
  .card--sidebar {
    padding: 26px 15px; }
    .card--sidebar .card-title {
      color: #27251f;
      font-size: 24px;
      font-size: 1.5rem;
      margin: 0;
      padding: 0; }
  @media (max-width: 991.98px) {
    .card-wrapper {
      padding: 0; } }
  .card-summary {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media (min-width: 992px) {
      .card-summary {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 70%;
                flex: 0 0 70%; } }
    .card-summary--full {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%; }
    .card-summary-title {
      color: #27251f;
      font-family: "museo-slab", Serif;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: normal;
      font-weight: bold; }
      .card-summary-title-icon {
        background-color: #f8f8f8;
        color: #DA291C;
        border-radius: 50%;
        display: block;
        font-size: 22px;
        font-size: 1.375rem;
        height: 44px;
        margin-right: 10px;
        margin-bottom: 20px;
        position: relative;
        top: 5px;
        width: 44px; }
        @media (min-width: 992px) {
          .card-summary-title-icon {
            display: inline-block;
            margin-bottom: 0; } }
        .card-summary-title-icon i {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .card-summary-inline-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
      margin-bottom: 10px;
      margin-top: 20px; }
      @media (min-width: 992px) {
        .card-summary-inline-list {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap; } }
      .card-summary-inline-list-item {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        line-height: 1em;
        margin-top: 10px; }
        @media (min-width: 992px) {
          .card-summary-inline-list-item {
            margin: 0; }
            .card-summary-inline-list-item::after {
              background-color: #414141;
              content: '';
              display: inline-block;
              height: 90%;
              margin-left: 10px;
              margin-right: 10px;
              position: relative;
              top: 1px;
              width: 1px; }
            .card-summary-inline-list-item:last-child::after {
              display: none; } }
        .card-summary-inline-list-item a {
          color: #DA291C;
          text-decoration: underline; }
    .card-summary-item {
      margin-top: 10px; }
      @media (min-width: 992px) {
        .card-summary-item {
          margin: 0; } }
      .card-summary-item a {
        text-decoration: underline; }
    .card-summary-columns {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
      margin-top: 40px; }
      @media (min-width: 992px) {
        .card-summary-columns {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap; } }
      .card-summary-columns-item {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%; }
        @media (min-width: 992px) {
          .card-summary-columns-item {
            -webkit-box-flex: 1;
                -ms-flex: 1 0 25%;
                    flex: 1 0 25%; } }
        @media (max-width: 991.98px) {
          .card-summary-columns-item {
            margin-bottom: 16px; } }
        .card-summary-columns-item-label {
          color: #909090; }
        .card-summary-columns-item-body {
          font-weight: bold; }
        .card-summary-columns-item a {
          color: #DA291C;
          margin-left: 5px;
          text-decoration: underline; }
  .card-links {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .card-links {
        border-left: 2px solid #d7d8d7;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 30%;
                flex: 0 0 30%;
        margin-top: 0;
        padding-left: 60px; } }
    .card-links-primary {
      margin-bottom: 15px; }
      .card-links-primary a {
        padding: 15px;
        width: 100%; }
        .card-links-primary a.icon-pdf::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-family: "Font Awesome 5 Pro";
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          content: '\F1C1';
          font-weight: 400;
          margin-right: 5px;
          color: #FFFFFF; }
    @media (min-width: 992px) {
      .card-links-primary ~ .card-links-title {
        margin-top: 80px; } }
    .card-links-title {
      font-weight: bold;
      margin-bottom: 10px; }
    .card-links-list {
      list-style: none;
      margin: 0;
      padding: 0; }
      .card-links-list-item {
        margin-bottom: 10px; }
        .card-links-list-item a {
          color: #3c3c3c;
          font-weight: normal;
          text-decoration: underline; }
          .card-links-list-item a::before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-family: "Font Awesome 5 Pro";
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: '\F1C1';
            font-weight: 400;
            margin-right: 5px;
            color: #DA291C; }
        .card-links-list-item:last-child a {
          color: #0075c9;
          font-weight: bold;
          text-decoration: none; }
          .card-links-list-item:last-child a::before {
            display: none; }
  .card-title {
    color: #DA291C;
    font-family: "museo-slab", Serif;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 40px 15px 16px 15px;
    position: relative; }
    @media (min-width: 992px) {
      .card-title {
        padding: 48px 40px 15px 40px; } }
    .card-title a {
      color: #DA291C;
      font-family: "Open Sans", Arial, Sans-Serif;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: normal;
      position: absolute;
      right: 15px;
      text-decoration: underline;
      top: 40px; }
      @media (min-width: 992px) {
        .card-title a {
          right: 40px;
          top: 48px; } }
  .card-subtitle {
    background-color: #f1f1f1;
    font-weight: bold;
    padding: 15px 0 15px 40px;
    text-transform: uppercase; }
  .card-body {
    border-top: 1px solid #d7d8d7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding: 15px; }
    @media (min-width: 992px) {
      .card-body {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        padding: 20px 40px; } }
    .card-body--full-width form {
      width: 100%; }
    .card-body--no-title {
      border-top: 0; }
      @media (min-width: 992px) {
        .card-body--no-title {
          padding: 48px 40px; } }
    .card-body:last-child {
      padding-bottom: 40px; }
    .card-body-item {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%; }
      @media (min-width: 992px) {
        .card-body-item:first-child {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 75%;
                  flex: 0 0 75%; }
        .card-body-item:nth-child(2) {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 25%;
                  flex: 0 0 25%; }
        .card-body-item:first-child:nth-last-child(n+3),
        .card-body-item:first-child:nth-last-child(n+3) ~ * {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 33%;
                  flex: 0 0 33%; }
        .card-body-item:nth-child(2) {
          -webkit-box-flex: 1 !important;
              -ms-flex: 1 0 42% !important;
                  flex: 1 0 42% !important; }
        .card-body-item:nth-last-child(1) {
          -webkit-box-flex: 0 !important;
              -ms-flex: 0 0 25% !important;
                  flex: 0 0 25% !important; } }
    @media (min-width: 992px) {
      .card-body-action {
        text-align: right; }
        .card-body-action, .card-body-action:nth-last-child(1) {
          -webkit-box-flex: 0 !important;
              -ms-flex: 0 0 15% !important;
                  flex: 0 0 15% !important; } }
    @media (max-width: 991.98px) {
      .card-body-action {
        margin-top: 10px; } }
    .card-body-action a {
      color: #DA291C;
      text-decoration: underline; }
    .card-body-label {
      font-weight: bold; }
      .card-body-label-sublabel {
        color: #707070;
        font-weight: normal;
        padding-top: 5px; }
    .card-body-value {
      margin-top: 15px;
      padding-right: 80px; }
      @media (min-width: 992px) {
        .card-body-value {
          margin-top: 0; } }
      .card-body-value:last-child {
        padding-right: 20px; }
      .card-body-value--full {
        -webkit-box-flex: 0 !important;
            -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
        margin-top: 50px; }
      .card-body-value-item-secondary {
        color: #707070; }
      .card-body-value-item-document {
        color: #3c3c3c;
        display: block;
        font-weight: normal;
        margin-bottom: 8px;
        text-decoration: underline; }
        .card-body-value-item-document i {
          color: #DA291C;
          margin-right: 4px; }
    .card-body ul {
      list-style: none;
      margin: 0 0 10px 18px;
      padding: 0; }
      .card-body ul li::before {
        content: '\2022';
        font-size: 18px;
        font-size: 1.125rem;
        padding-right: 8px; }
      .card-body ul ul {
        margin-bottom: 0; }
    .card-body-timeline {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin: 0 auto;
      list-style: none;
      padding: 0;
      width: 100%; }
      @media (min-width: 992px) {
        .card-body-timeline {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          margin-bottom: 20px; } }
      .card-body-timeline li {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 200px;
                flex: 0 0 200px;
        position: relative;
        text-align: center; }
        @media (max-width: 991.98px) {
          .card-body-timeline li {
            -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
            height: 145px;
            padding-left: 80px;
            padding-top: 15px;
            text-align: left; } }
        .card-body-timeline li::before {
          background-color: #f8f8f8;
          border: 2px solid #707070;
          border-radius: 50%;
          content: '\F00C';
          color: #FFFFFF;
          display: block;
          height: 60px;
          left: 50%;
          margin-bottom: 10px;
          font-family: "Font Awesome 5 Pro";
          padding-top: 15px;
          position: relative;
          -webkit-transform: translate(-50%);
              -ms-transform: translate(-50%);
                  transform: translate(-50%);
          width: 60px;
          z-index: 1; }
          @media (max-width: 991.98px) {
            .card-body-timeline li::before {
              left: 0;
              position: absolute;
              top: 0;
              text-align: center;
              -webkit-transform: none;
                  -ms-transform: none;
                      transform: none; } }
        .card-body-timeline li::after {
          background-color: #707070;
          content: '';
          display: block;
          height: 2px;
          position: absolute;
          right: -69px;
          top: -webkit-calc(50% - 17px);
          top: calc(50% - 17px);
          width: 180px;
          z-index: 0; }
          @media (max-width: 991.98px) {
            .card-body-timeline li::after {
              left: 30px;
              height: 84px;
              right: auto;
              top: 60px;
              width: 2px; } }
        .card-body-timeline li:last-child::after {
          display: none; }
        @media (max-width: 991.98px) {
          .card-body-timeline li:last-child {
            height: 80px; } }
        .card-body-timeline li.complete::before {
          background-color: #0075c9;
          border-color: #0075c9; }
  .card--single .card-title {
    color: #27251f;
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 44px;
    line-height: 2.75rem;
    padding-bottom: 10px; }
  .card--single .card-summary-item {
    padding: 0 15px 10px 15px; }
    @media (min-width: 992px) {
      .card--single .card-summary-item {
        padding: 0 40px 20px 40px; } }
  .card-jump-to {
    margin-top: 32px; }
    .card-jump-to ul {
      -webkit-column-gap: 10px;
         -moz-column-gap: 10px;
              column-gap: 10px;
      row-gap: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column wrap;
              flex-flow: column wrap;
      list-style: none;
      margin: 0;
      padding: 0; }
      @media (min-width: 992px) {
        .card-jump-to ul {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap; } }
    .card-jump-to-label {
      color: #707070; }
    .card-jump-to a {
      color: #3c3c3c;
      text-decoration: underline; }
  .card-additional-navigation {
    padding-bottom: 30px; }
    .card-additional-navigation ul {
      -webkit-column-gap: 15px;
         -moz-column-gap: 15px;
              column-gap: 15px;
      list-style: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin: 0;
      padding: 0;
      width: 100%; }
      @media (max-width: 991.98px) {
        .card-additional-navigation ul li {
          margin-bottom: 8px; } }
      .card-additional-navigation ul a {
        color: #0075c9;
        text-decoration: underline; }
  .card--form {
    padding-bottom: 16px; }
    .card--form .card-title {
      color: #27251f;
      margin-bottom: 17px; }
  .card .payment-due {
    background-color: #EAAA00;
    display: inline-block;
    padding: 4px 8px; }
  .card-instructions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    padding: 0 16px 16px 16px; }
    @media (min-width: 992px) {
      .card-instructions {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        padding: 0 48px 48px 48px; } }
    .card-instructions-body {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%; }
      @media (min-width: 992px) {
        .card-instructions-body {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 60%;
                  flex: 0 0 60%; } }
      .card-instructions-body ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .card-instructions-body ul li {
          line-height: 18px;
          line-height: 1.125rem;
          margin-bottom: 15px; }
        .card-instructions-body ul li:before {
          content: "\F111";
          display: inline-block;
          display: inline-block;
          font-family: "Font Awesome 5 Pro";
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-size: 4px;
          font-size: 0.25rem;
          font-weight: 700;
          padding-right: 8px;
          position: relative;
          top: -4px; }
    .card-instructions-actions {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%; }
      @media (min-width: 992px) {
        .card-instructions-actions {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 40%;
                  flex: 0 0 40%;
          text-align: right; } }
      @media (max-width: 991.98px) {
        .card-instructions-actions a {
          width: 100%; } }
  .card-pagination-wrapper {
    border-top: 1px solid #d7d8d7;
    padding-bottom: 48px;
    padding-top: 24px;
    width: 100%; }
  .card-pagination {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .card-pagination-prev {
      background-image: url("/Resources/Images/arrow-gray.png");
      background-repeat: no-repeat;
      cursor: pointer;
      height: 60px;
      width: 60px; }
    .card-pagination-label {
      padding-left: 24px;
      padding-right: 24px; }
    .card-pagination-next {
      background-image: url("/Resources/Images/arrow-red.png");
      background-repeat: no-repeat;
      cursor: pointer;
      height: 60px;
      width: 60px; }

@media (max-width: 991.98px) {
  .container > .card {
    margin-left: 0;
    margin-right: 0;
    width: 100%; } }
