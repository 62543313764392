@media (min-width: 992px) {
  .secondary-nav-container .container {
    max-width: 1300px; } }

.secondary-nav li a {
  font-size: 15px !important;
  font-size: 0.9375rem !important;
  padding: 15px 0 !important; }

.secondary-nav li:hover,
.secondary-nav li.active {
  border-bottom: 4px solid #27251f !important;
  font-weight: 600; }
