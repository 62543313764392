.success-message {
  margin-bottom: 40px; }
  @media (max-width: 991.98px) {
    .success-message {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 40px;
      width: -webkit-calc(100% - 40px);
      width: calc(100% - 40px); } }
  .success-message .status-message {
    padding: 100px 20px 20px 20px;
    text-align: center; }
    @media (min-width: 992px) {
      .success-message .status-message {
        padding: 50px 50px 50px 100px;
        text-align: left; } }
    .success-message .status-message b {
      color: #27251f;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      line-height: 3.25rem;
      font-family: "museo-slab", Serif; }
    .success-message .status-message::after {
      left: 50%;
      top: 40px; }
      @media (min-width: 992px) {
        .success-message .status-message::after {
          left: 20px;
          top: 65px; } }
