.stepper {
  background-color: #FFFFFF !important;
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .stepper {
      padding-top: 40px;
      padding-bottom: 40px; } }
  .stepper .step {
    background-color: #FFFFFF; }
    @media (min-width: 992px) {
      .stepper .step {
        padding-left: 40px !important; } }
    .stepper .step-body {
      border-left: 3px solid #d7d8d7 !important; }
      @media (min-width: 992px) {
        .stepper .step-body {
          padding-left: 40px !important;
          padding-right: 90px !important; } }
      .stepper .step-body p,
      .stepper .step-body div {
        font-size: 16px;
        font-size: 1rem; }
        .stepper .step-body p a,
        .stepper .step-body div a {
          font-weight: 600;
          font-size: 16px;
          font-size: 1rem; }
      .stepper .step-body .save-pdf {
        display: inline-block;
        font-weight: 700;
        font-size: 16px;
        font-size: 1rem;
        margin-bottom: 20px;
        text-transform: uppercase; }
        .stepper .step-body .save-pdf i {
          color: #DA291C;
          margin-right: 10px; }
      .stepper .step-body.no-border {
        border-left-color: #FFFFFF !important; }
    .stepper .step-heading {
      border-left: 3px solid #d7d8d7 !important;
      font-family: "Open Sans", Arial, Sans-Serif;
      font-weight: 600;
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 28px;
      line-height: 1.75rem;
      padding-left: 30px !important; }
      @media (min-width: 992px) {
        .stepper .step-heading {
          padding-left: 40px !important; } }
      .stepper .step-heading::before {
        background-color: #FFFFFF;
        border: 3px solid #d7d8d7;
        border-radius: 50%;
        content: '' !important;
        height: 31px;
        left: 6px !important;
        width: 31px; }
        @media (min-width: 992px) {
          .stepper .step-heading::before {
            left: 26px !important; } }
    .stepper .step.active .step-heading {
      color: #DA291C;
      font-family: "museo-slab", Serif;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 34px;
      line-height: 2.125rem;
      padding: 10px 30px 30px 30px; }
      .stepper .step.active .step-heading::before {
        background-color: #DA291C !important;
        border-color: #DA291C; }
    .stepper .step:first-child {
      padding-top: 0; }
      .stepper .step:first-child .step-heading {
        padding-top: 0; }
      .stepper .step:first-child.active {
        padding-top: 0; }
    .stepper .step.done .step-heading {
      color: #707070; }
      .stepper .step.done .step-heading::before {
        background-color: #d7d8d7 !important;
        border-color: #d7d8d7;
        color: #FFFFFF !important;
        content: '\F00C' !important;
        padding-left: 4px; }
    .stepper .step.active.done .step-heading {
      color: #DA291C;
      line-height: 28px;
      line-height: 1.75rem;
      padding-bottom: 0; }
      .stepper .step.active.done .step-heading::before {
        background-color: #d7d8d7 !important;
        border-color: #d7d8d7;
        color: #FFFFFF !important;
        content: '\F00C' !important;
        font-size: 16px;
        font-size: 1rem;
        padding-left: 4px; }
    .stepper .step.active.done .step-body {
      border-left: 0 !important; }
  .stepper .step:first-of-type {
    border-radius: 10px 10px 0 0; }
    .stepper .step:first-of-type .step-heading {
      padding-top: 0; }
