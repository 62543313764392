.policy-listing {
  padding: 24px 15px !important; }
  @media (min-width: 992px) {
    .policy-listing {
      padding: 24px 40px !important; } }
  .policy-listing-card {
    padding: 24px 0; }
  .policy-listing .policy-number {
    color: #27251f;
    font-weight: 600; }
    .policy-listing .policy-number a {
      color: #27251f; }
  .policy-listing .policy-description {
    margin-top: 10px; }
    .policy-listing .policy-description a {
      color: #0075c9;
      font-weight: 600; }
    .policy-listing .policy-description i {
      color: #DA291C; }
    @media (max-width: 991.98px) {
      .policy-listing .policy-description-link {
        float: none !important;
        margin-top: 10px; } }
