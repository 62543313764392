.payment-options-title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 700;
  margin-bottom: 20px; }

.payment-options-list {
  border: 0; }
  .payment-options-list .list-group-item {
    border: 0;
    border-bottom: 1px solid #d7d8d7;
    margin-bottom: 0; }
    .payment-options-list .list-group-item:last-child {
      border-bottom: 0; }

.payment-option-label {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 400; }
  .payment-option-label span {
    font-size: 14px;
    font-size: 0.875rem; }

.payment-option-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 700;
  margin-top: 5px; }

.payment-policy-number {
  color: #27251f;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 600;
  font-family: "Open Sans", Arial, Sans-Serif;
  display: block; }

.payment-policy-title {
  color: #DA291C;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 700;
  font-family: "museo-slab", Serif;
  display: block; }

.payment-options-description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  padding-top: 5px; }

.payment-options.table-responsive-stack > .row {
  padding: 15px; }

.payment-back-link {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  text-transform: uppercase;
  font-weight: 700; }
  .payment-back-link .icon {
    height: 19px;
    margin-right: 5px;
    position: relative;
    top: -2px;
    width: 19px; }

.payment-heading {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  text-align: left; }
  @media (max-width: 991.98px) {
    .payment-heading {
      margin-bottom: 10px; } }
  .payment-heading .semi-bold {
    margin-bottom: 5px; }

.caption-billing {
  color: #27251f;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem; }

.payment-total {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 600;
  text-align: left;
  position: relative;
  top: 6px; }
  .payment-total-actions {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    margin-top: 20px; }
    @media (max-width: 991.98px) {
      .payment-total-actions {
        margin-left: 0; } }
    @media (min-width: 992px) {
      .payment-total-actions {
        margin-top: 0; } }
    .payment-total-actions .btn {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      margin-bottom: 15px; }
      @media (min-width: 992px) {
        .payment-total-actions .btn {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 47%;
                  flex: 0 0 47%;
          padding: 11px 15px; } }

.payment-total-container {
  border-top: 1px solid #d7d8d7;
  padding-top: 20px; }

.payment-text-info {
  font-size: 16px;
  font-size: 1rem;
  line-height: 26px;
  line-height: 1.625rem;
  margin-top: 20px;
  text-align: left; }
  .payment-text-info p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem; }

.payment-disclaimer {
  color: #707070;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  margin-top: 20px; }
  .payment-disclaimer p {
    color: #707070;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem; }

#paymentOptions .panel-body {
  padding-bottom: 0; }

.material-card--padded .title.no-payment-title,
.no-payment-title {
  color: #DA291C;
  font-size: 24px !important;
  font-size: 1.5rem !important;
  line-height: 34px;
  line-height: 2.125rem;
  padding-top: 0 !important; }

.payment-section {
  position: relative; }
  .payment-section .btn {
    margin-bottom: 20px; }
  .payment-section .form-actions {
    margin-top: 0 !important; }

.payment-history-heading {
  color: #27251f;
  font-family: "Open Sans", Arial, Sans-Serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 600; }
  .payment-history-heading-panel {
    padding-left: 20px !important;
    padding-right: 20px !important; }

.payment-history-item-heading {
  font-weight: 400; }
  .payment-history-item-heading-label {
    font-weight: 700; }

.payment-history-item-body {
  padding-left: 20px;
  padding-right: 20px; }

.payment-history-item-label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  font-weight: 400; }

.payment-history-item-col {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .payment-history-item-col {
      margin-bottom: 0; } }

.payment-history-panel {
  padding-bottom: 0; }

.payment-options-container .panel-group .panel + .panel {
  border-top: 0; }

@media (min-width: 992px) {
  .payment-panel {
    padding-left: 15px;
    padding-right: 15px; }
    .payment-panel a .panel-heading::after {
      right: 0 !important; } }

@media (min-width: 992px) {
  .policy-payment-options {
    margin-top: 44px; } }

.payment-save-time {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  margin-top: 0 !important; }
  @media (min-width: 992px) {
    .payment-save-time {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0; } }
  .payment-save-time p {
    font-size: 16px;
    font-size: 1rem; }
  .payment-save-time a {
    text-decoration: underline; }

.payments-actions {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }
  @media (min-width: 992px) {
    .payments-actions {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      margin-top: 20px;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; } }

.payment-option-link {
  padding-left: 20px; }
  .payment-option-link .btn--link {
    padding: 5px 0 !important;
    text-align: left; }

.payment-option-description {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  line-height: 1.375rem;
  margin-top: 2px;
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px; }
