.claim-details {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0 !important; }
  .claim-details .table-responsive-stack .head {
    background-color: transparent; }
  .claim-details .panel,
  .claim-details .panel-group {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    padding: 0 !important; }
  .claim-details .panel-heading {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    border-radius: 0; }
    .claim-details .panel-heading .panel-title {
      font-size: 18px;
      font-size: 1.125rem; }
  @media (min-width: 992px) {
    .claim-details .material-card .table-responsive-stack > .row:not(.head) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 991.98px) {
    .claim-details .timeline li {
      padding-left: 40px; }
      .claim-details .timeline li p {
        text-align: left; } }

.claim-highlight-container {
  background-color: #0075c9;
  padding-bottom: 20px;
  padding-top: 20px; }
  @media (min-width: 992px) {
    .claim-highlight-container .container {
      max-width: 1300px; } }
  .claim-highlight-container li a {
    color: #FFFFFF !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .claim-highlight-container .dropdown .dropdown-toggle::after {
    border: 0 !important;
    font-size: 30px;
    font-size: 1.875rem;
    position: relative;
    top: 10px; }
  .claim-highlight-container .policy-number {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 34px;
    line-height: 2.125rem; }
  .claim-highlight-container .policy-description {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem; }
