.panel--profile {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .panel--profile {
      margin-top: 40px; } }

.panel-group {
  background-color: #FFFFFF;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  border-top: 0 !important;
  overflow: hidden;
  padding-bottom: 10px; }
  @media (max-width: 991.98px) {
    .panel-group {
      width: 100%; } }
  .panel-group .panel {
    border-bottom: 1px solid #d7d8d7 !important; }
    .panel-group .panel:last-of-type {
      border-bottom: 0 !important; }
    @media (max-width: 991.98px) {
      .panel-group .panel {
        margin: auto;
        width: 100%; } }

.panel-group-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: #DA291C;
  font-family: "museo-slab", Serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 15px; }
  @media (max-width: 991.98px) {
    .panel-group-header {
      margin: auto;
      width: 100%; } }

.panel-title {
  color: #DA291C;
  font-family: "museo-slab", Serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 700 !important; }

.panel-heading {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 600;
  font-family: "Open Sans", Arial, Sans-Serif;
  padding: 20px !important; }
  @media (max-width: 991.98px) {
    .panel-heading {
      margin: auto;
      width: 100%; } }
  .panel-heading::after {
    font-size: 28px;
    font-size: 1.75rem; }

.panel-trigger:hover, .panel-trigger:focus {
  text-decoration: none; }

.panel-trigger[aria-expanded="true"] .panel-heading::after {
  content: '\F106' !important; }

.panel a[aria-expanded="true"] .panel-heading::after {
  content: '\F106' !important; }

.profile-panel .panel-heading {
  border: 0 !important; }
  .profile-panel .panel-heading::after {
    top: 25px !important;
    right: 25px !important; }

.panel a:hover,
.panel a:active,
.panel a:focus {
  text-decoration: none !important; }
