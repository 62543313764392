.policy-details .item {
  border-radius: 10px; }

.policy-details .table-responsive-stack {
  background-color: #FFFFFF;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  border-top: 0 !important; }

.policy-details .head {
  border-top: 0 !important; }

.policy-details .title {
  color: #DA291C;
  font-family: "museo-slab", Serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 700;
  padding-top: 6px;
  padding-bottom: 6px; }
  .policy-details .title .help-links {
    font-size: 18px;
    font-size: 1.125rem;
    font-family: "Open Sans", Arial, Sans-Serif;
    font-weight: 600; }
  .policy-details .title i {
    color: #27251f; }

.policy-details .title-label {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: right;
  padding-top: 9px; }

.policy-details .policy-label {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  line-height: 1.375rem; }

.policy-details .subrow .policy-label {
  font-weight: 400; }

.policy-details .policy-value {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  line-height: 1.375rem;
  text-align: right; }

.policy-details .subsection .head {
  background-color: #d7d8d7 !important; }
  .policy-details .subsection .head .title {
    color: #27251f;
    font-family: "Open Sans", Arial, Sans-Serif;
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem; }

.save-link {
  display: block;
  margin-bottom: 20px; }
  .save-link a {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase; }
  .save-link i {
    color: #DA291C; }

.back-link {
  font-size: 16px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700; }
  .back-link i {
    color: #DA291C; }

@media (min-width: 992px) {
  .policy-coverage-links-container {
    max-width: 1300px; } }

.policy-coverage-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  text-align: left; }
  .policy-coverage-links li {
    float: none;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media (min-width: 992px) {
      .policy-coverage-links li {
        border-right: 1px solid #d7d8d7;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        padding-right: 10px; } }
    .policy-coverage-links li:first-of-type {
      border-right: 1px solid #d7d8d7; }
    .policy-coverage-links li:last-of-type {
      border-right: none; }

.policy-coverage-links li a {
  color: #0075c9;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase; }

.policycontextnav {
  margin-left: 0; }
  .policycontextnav.nav .open > a {
    background-color: #0075c9; }
  .policycontextnav .dropdown-menu > li > a {
    border-bottom: 1px solid #d7d8d7;
    color: #27251f !important;
    padding: 10px !important; }
    @media (min-width: 992px) {
      .policycontextnav .dropdown-menu > li > a {
        padding: 10px 15px !important; } }
