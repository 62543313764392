.tabs {
  border: none; }
  .tabs--filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    list-style: none; }
    @media (min-width: 992px) {
      .tabs--filter {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  .tabs .tab-title {
    font-family: "museo-slab", Serif;
    font-size: 32px;
    font-size: 2rem;
    font-weight: 700;
    float: none;
    text-align: left;
    white-space: normal; }
    @media (min-width: 992px) {
      .tabs .tab-title {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 52px;
        line-height: 3.25rem; } }
    .tabs .tab-title--underline {
      border-bottom: 1px solid #d7d8d7;
      padding-bottom: 20px;
      padding-top: 0; }
    .tabs .tab-title .tab-subtitle {
      display: block;
      font-family: "Open Sans", Arial, Sans-Serif;
      font-weight: normal;
      font-size: 18px; }
      .tabs .tab-title .tab-subtitle a {
        color: #3c3c3c;
        font-weight: normal;
        text-decoration: underline; }
  .tabs .tab-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
    .tabs .tab-filter .label {
      color: #27251f;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: normal;
      position: relative; }
      @media (max-width: 991.98px) {
        .tabs .tab-filter .label {
          display: block;
          margin-bottom: 8px;
          padding: 0; } }
      @media (min-width: 992px) {
        .tabs .tab-filter .label {
          margin-right: 32px;
          top: 12px; } }
