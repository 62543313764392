.row + .card {
  margin-top: 32px; }

.card--link {
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
  padding: 24px 16px; }
  @media (max-width: 991.98px) {
    .card--link {
      width: 100%; } }
  @media (min-width: 992px) {
    .card--link {
      padding: 48px 40px; } }
  .card--link .card-title {
    padding: 0;
    color: #DA291C;
    font-family: "museo-slab", Serif;
    font-weight: 600;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 34px;
    line-height: 2.125rem; }
    .card--link .card-title::after {
      background-image: url("/Resources/Images/icon-arrow-right.png");
      content: '';
      display: inline-block;
      height: 24px;
      left: 10px;
      position: relative;
      top: 5px;
      width: 24px; }
