.page-title-wrapper {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  @media (min-width: 992px) {
    .page-title-wrapper {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }

.page-title {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .page-title {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      margin-bottom: 0 !important; } }

.page-title-btn {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
  @media (min-width: 992px) {
    .page-title-btn {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; } }
  .page-title-btn a {
    width: 100%; }

.help-links--title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  left: 15px;
  bottom: -20px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .help-links--title {
      bottom: 0;
      left: auto;
      position: absolute;
      top: 30px;
      right: 15px; } }
  .help-links--title .icon {
    margin-right: 10px; }
