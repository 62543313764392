.agent-card {
  padding: 20px; }
  @media (min-width: 992px) {
    .agent-card-content-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      position: relative; } }
  .agent-card-photo {
    display: none; }
    @media (min-width: 992px) {
      .agent-card-photo {
        border-radius: 4px !important;
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 88px;
                flex: 0 0 88px;
        float: none !important;
        margin: 0 !important;
        height: 88px;
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        position: absolute !important;
        top: 0;
        right: 0; }
        .agent-card-photo img {
          position: relative;
          top: auto;
          left: auto; } }
  .agent-card-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding-right: 20px;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
    .agent-card-content .title {
      font-size: 24px !important;
      font-size: 1.5rem !important;
      line-height: 34px;
      line-height: 2.125rem;
      font-weight: 600 !important;
      padding: 0 !important;
      max-width: -webkit-calc(100% - 75px);
      max-width: calc(100% - 75px); }
    .agent-card-content .support-text {
      margin-top: 20px;
      padding: 0 !important;
      line-height: 20px;
      line-height: 1.25rem; }
  .agent-card .agent-card-phone,
  .agent-card .agent-card-email,
  .agent-card .agent-card-website {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px; }
    .agent-card .agent-card-phone .icon,
    .agent-card .agent-card-email .icon,
    .agent-card .agent-card-website .icon {
      margin-right: 10px; }
    .agent-card .agent-card-phone a,
    .agent-card .agent-card-email a,
    .agent-card .agent-card-website a {
      color: #27251f; }
  .agent-card-actions {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding: 0 !important; }
    @media (min-width: 992px) {
      .agent-card-actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        margin-top: 20px; } }
    .agent-card-actions a {
      display: block;
      font-size: 16px;
      font-size: 1rem;
      line-height: 22px;
      line-height: 1.375rem;
      font-weight: 700;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 46%;
              flex: 0 0 46%;
      padding: 0 !important; }
