.card-table {
  margin-left: 15px;
  margin-right: 15px;
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px); }
  @media (min-width: 992px) {
    .card-table {
      display: table;
      margin-left: 0;
      margin-right: 0;
      padding-top: 48px;
      width: 100%; } }
  @media (min-width: 992px) {
    .card-table-header {
      display: table-header-group; } }
  .card-table-header-cell {
    display: none;
    font-weight: bold; }
    @media (min-width: 992px) {
      .card-table-header-cell {
        display: table-cell;
        padding-bottom: 24px; } }
    .card-table-header-cell:first-child {
      padding-left: 16px; }
      @media (min-width: 992px) {
        .card-table-header-cell:first-child {
          padding-left: 40px; } }
  .card-table-header-title {
    color: #DA291C;
    display: block;
    font-family: "museo-slab", Serif;
    font-size: -webkit-calc(1.275rem + 0.3vw);
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 600;
    padding: 0 16px 24px 16px; }
    @media (min-width: 1200px) {
      .card-table-header-title {
        font-size: 1.5rem; } }
    @media (min-width: 992px) {
      .card-table-header-title {
        display: table-cell;
        padding: 0 0 24px 40px; } }
    .card-table-header-title .card-summary-title-icon {
      display: inline-block;
      top: 15px; }
      @media (max-width: 991.98px) {
        .card-table-header-title .card-summary-title-icon {
          margin-bottom: 0; } }
  @media (min-width: 992px) {
    .card-table-body {
      display: table-row-group; } }
  .card-table-row {
    border-top: 1px solid #d7d8d7;
    padding: 24px 16px;
    position: relative; }
    @media (max-width: 991.98px) {
      .card-table-row:first-child {
        border-top: 0; } }
    @media (min-width: 992px) {
      .card-table-row {
        border-top: 0;
        display: table-row;
        padding: 0; } }
  .card-table-cell {
    padding-bottom: 10px; }
    @media (min-width: 992px) {
      .card-table-cell {
        border-top: 1px solid #d7d8d7;
        display: table-cell;
        padding-bottom: 24px;
        padding-top: 24px; }
        .card-table-cell:first-child {
          padding-left: 16px; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .card-table-cell:first-child {
        padding-left: 40px; } }
    @media (min-width: 992px) {
        .card-table-cell:last-child {
          padding-right: 16px; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .card-table-cell:last-child {
        padding-right: 40px; } }
    .card-table-cell-heading {
      font-weight: bold; }
    .card-table-cell-subheading {
      color: #707070;
      display: block; }
    @media (min-width: 992px) {
      .card-table-cell-grid {
        -webkit-column-gap: 40px;
           -moz-column-gap: 40px;
                column-gap: 40px;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 125px 1fr;
            grid-template-columns: 125px 1fr;
        -ms-grid-rows: auto;
            grid-template-rows: auto; } }
    @media (max-width: 991.98px) {
      .card-table-cell-grid-item {
        padding-bottom: 10px; } }
    .card-table-cell-additional-info {
      height: 0;
      visibility: hidden; }
      .card-table-cell-additional-info.open {
        height: auto;
        padding-top: 24px;
        visibility: visible; }
      .card-table-cell-additional-info-label {
        font-weight: bold;
        padding-bottom: 10px; }
    @media (max-width: 991.98px) {
      .card-table-cell .amount {
        position: absolute;
        right: 16px;
        top: 25px; } }
    @media (max-width: 991.98px) {
      .card-table-cell-arrow {
        margin-bottom: -30px; } }
    @media (min-width: 992px) {
      .card-table-cell-arrow {
        position: relative;
        text-align: right; } }
    .card-table-cell-arrow .arrow {
      position: absolute;
      right: 40px; }
      @media (max-width: 991.98px) {
        .card-table-cell-arrow .arrow {
          bottom: 20px; } }
      .card-table-cell-arrow .arrow.open {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
  @media (min-width: 992px) {
    .card-table-actions {
      text-align: right; } }
  .card-table-actions a {
    text-decoration: underline; }
    .card-table-actions a::after {
      content: '|';
      color: #3c3c3c;
      display: inline-block;
      font-size: 1rem;
      margin-left: 10px;
      margin-right: 10px;
      position: relative;
      top: -2px;
      width: 1px; }
    .card-table-actions a:last-child::after {
      display: none; }
  .card-table-actions-secondary {
    color: #707070; }
  .card-table-pill {
    display: inline-block; }
    .card-table-pill.text-primary {
      color: #3c3c3c; }
    .card-table-pill.text-success {
      background: #f8f8f8;
      border-radius: 25px;
      color: #DA291C;
      font-weight: bold;
      padding: 11px 20px;
      text-align: center; }
  @media (min-width: 992px) {
    .card-table--3-col .card-table-header-cell,
    .card-table--3-col .card-table-cell {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      width: 33.333333%; }
    .card-table--3-col .card-table-header-cell:first-child,
    .card-table--3-col .card-table-cell:first-child {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 42%;
              flex: 0 0 42%;
      width: 42%; }
    .card-table--3-col .card-table-header-cell:last-child,
    .card-table--3-col .card-table-cell:last-child {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 24.33333%;
              flex: 0 0 24.33333%;
      width: 24.333333%; } }
  .card-table .arrow {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-DownArrow%3C/title%3E%3Cg id='icon-DownArrow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Container-24px' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M20.5812309,9.28590475 C20.9386353,8.95124925 21.0812795,8.45715689 20.9545422,7.99282492 C20.8278049,7.52849295 20.4513216,7.16586015 19.9692548,7.04378544 C19.4871879,6.92171073 18.974224,7.0591071 18.6267866,7.40336289 L12,13.7863554 L5.37321157,7.40336171 C5.02577382,7.05910685 4.5128104,6.92171116 4.03074422,7.04378592 C3.54867804,7.16586068 3.17219527,7.52849293 3.04545794,7.99282427 C2.91872062,8.4571556 3.06136405,8.9512475 3.41876751,9.28590328 L11.0227778,16.6101679 C11.5625143,17.129944 12.4374856,17.129944 12.977222,16.610168 L20.5812309,9.28590475 Z' id='icon-DownArrow-Dark-Grey' fill='%2327251F'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: 25px;
    cursor: pointer;
    display: block;
    height: 25px;
    width: 25px; }
