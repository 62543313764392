@media (max-width: 991.98px) {
  .temp-title {
    margin-left: 15px; } }

.card-grid {
  border-bottom: 1px solid #d7d8d7;
  padding: 24px 16px; }
  @media (min-width: 992px) {
    .card-grid {
      padding: 24px 20px 24px 40px; } }
  .card-grid:first-child {
    padding-top: 48px; }
  .card-grid:last-child {
    padding-bottom: 48px; }
  @media (min-width: 992px) {
    .card-grid--two-col {
      -webkit-column-gap: 40px;
         -moz-column-gap: 40px;
              column-gap: 40px;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: calc(65% - 20px) calc(35% - 20px);
          grid-template-columns: -webkit-calc(65% - 20px) -webkit-calc(35% - 20px);
          grid-template-columns: calc(65% - 20px) calc(35% - 20px); } }
  .card-grid-column:first-child {
    margin-bottom: 24px; }
    @media (min-width: 992px) {
      .card-grid-column:first-child {
        margin-bottom: 0; } }
  .card-grid-column-title, .card-grid-column-title {
    color: #DA291C;
    cursor: pointer;
    font-family: "museo-slab", Serif;
    font-weight: 600;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 34px;
    line-height: 2.125rem; }
    .card-grid-column-title a, .card-grid-column-title a {
      color: #DA291C;
      cursor: pointer;
      font-family: "museo-slab", Serif;
      font-weight: 600;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 34px;
      line-height: 2.125rem; }
      .card-grid-column-title a:hover, .card-grid-column-title a:hover {
        text-decoration: none; }
    .card-grid-column-title::after, .card-grid-column-title::after {
      background-image: url("/Resources/Images/icon-arrow-right.png");
      content: '';
      display: inline-block;
      height: 24px;
      left: 10px;
      position: relative;
      top: 5px;
      width: 24px; }
    .card-grid-column-title--external::after, .card-grid-column-title--external::after {
      background-image: url("/Resources/Images/icon-external.png"); }
    .card-grid-column-title--no-icon::after, .card-grid-column-title--no-icon::after {
      display: none; }
  .card-grid-column-detail {
    font-size: 16px;
    font-size: 1rem; }
    .card-grid-column-detail-grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column wrap;
              flex-flow: column wrap; }
      .card-grid-column-detail-grid-item {
        font-size: 16px;
        font-size: 1rem;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; }
      @media (min-width: 992px) {
        .card-grid-column-detail-grid {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column wrap;
                  flex-flow: column wrap; } }
    .card-grid-column-detail-actions {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-grid-column-detail-actions-item {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; }
        .card-grid-column-detail-actions-item::after {
          content: "|";
          display: inline-block;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          padding: 0 8px; }
        .card-grid-column-detail-actions-item:last-child::after {
          display: none; }
