.card-form .payment-due {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 16px;
  font-size: 1rem; }
  @media (min-width: 992px) {
    .card-form .payment-due {
      margin: 0;
      margin-left: 16px; } }

.card-form .form-group {
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 992px) {
    .card-form .form-group {
      padding-left: 40px;
      padding-right: 40px; } }

.card-form .radio-inline {
  margin-bottom: 0;
  width: 100%; }

.card-form .radio-inline input[type="radio"],
.card-form .radio input[type="radio"] {
  top: 0; }

.card-body-form {
  width: 100%; }

.card--profile-form {
  margin-top: 32px; }
