@media (min-width: 992px) {
  .security-questions {
    padding-left: 40px;
    padding-right: 40px; } }

.security-questions .question {
  border-bottom: 1px solid #d7d8d7;
  padding: 40px 0; }
  .security-questions .question:last-child {
    border-bottom: 0; }

.security-questions .form-group {
  display: block;
  margin: 0;
  width: 100%; }

.security-questions-container {
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  float: none; }
  .security-questions-container label {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 28px;
    line-height: 1.75rem;
    margin: 0 0 20px 0;
    text-align: left; }
    @media (min-width: 992px) {
      .security-questions-container label {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        margin: 0; } }
    .security-questions-container label:first-child {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      font-weight: 600; }
  .security-questions-container button {
    background-color: transparent !important;
    color: #0075c9 !important;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    min-width: 50px;
    margin: 0;
    padding: 0;
    text-align: left; }
    @media (min-width: 992px) {
      .security-questions-container button {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50px;
                flex: 0 0 50px;
        text-align: center; } }
    .security-questions-container button:hover, .security-questions-container button:focus {
      background-color: transparent !important;
      color: #DA291C !important; }

.security-questions-edit .form-group {
  margin-bottom: 20px; }
