.policyforms-table .material-card-title {
  line-height: 34px;
  line-height: 2.125rem;
  padding: 20px !important; }

.policyforms-table tr th,
.policyforms-table tr td {
  line-height: normal !important; }

.policyforms-table tr td {
  border-top: 1px solid #d7d8d7 !important;
  vertical-align: middle !important; }

.policyforms-pdflink {
  color: #0075c9;
  font-weight: 600;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  padding: 15px 20px !important; }
  .policyforms-pdflink:hover {
    text-decoration: none; }
  .policyforms-pdflink i {
    color: #DA291C; }

.policyforms-note {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem; }
