.modal-header,
.modal-dialog-header {
  padding-left: 0;
  padding-right: 0; }

.modal-body,
.modal-dialog-body {
  padding-left: 0;
  padding-right: 0; }

.modal-content,
.modal-dialog-content {
  border: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
          box-shadow: 0 14px 44px rgba(0, 0, 0, 0.09);
  padding: 15px; }
  @media (min-width: 992px) {
    .modal-content,
    .modal-dialog-content {
      padding: 30px; } }

.modal-title,
.modal-dialog-title {
  color: #DA291C;
  font-family: "museo-slab", Serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-weight: 700; }

.modal-footer,
.modal-dialog-footer {
  padding: 0;
  width: 100%; }
  .modal-footer .actions,
  .modal-footer .form-actions,
  .modal-dialog-footer .actions,
  .modal-dialog-footer .form-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    padding: 0 15px !important;
    width: 100%; }
    .modal-footer .actions .btn,
    .modal-footer .form-actions .btn,
    .modal-dialog-footer .actions .btn,
    .modal-dialog-footer .form-actions .btn {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      @media (min-width: 992px) {
        .modal-footer .actions .btn,
        .modal-footer .form-actions .btn,
        .modal-dialog-footer .actions .btn,
        .modal-dialog-footer .form-actions .btn {
          margin-bottom: 0; } }
      @media (min-width: 992px) {
        .modal-footer .actions .btn--secondary,
        .modal-footer .form-actions .btn--secondary,
        .modal-dialog-footer .actions .btn--secondary,
        .modal-dialog-footer .form-actions .btn--secondary {
          padding-left: 25px !important;
          padding-right: 25px !important; } }
  .modal-footer .form-actions,
  .modal-dialog-footer .form-actions {
    padding: 0 15px !important; }
  .modal-footer-actions,
  .modal-dialog-footer-actions {
    padding: 0 !important; }

.modal .form textarea,
.modal-dialog .form textarea {
  height: 120px !important; }

@media (min-width: 992px) {
  .modal-dialog {
    width: 730px; } }
