.form.profile-section {
  padding: 0; }
  @media (min-width: 992px) {
    .form.profile-section {
      margin-left: -15px; } }

.form-group {
  margin-bottom: 32px;
  padding-bottom: 0; }

.form label {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0; }

.form .highlight {
  border-radius: 0;
  background-color: #f8f8f8;
  padding: 20px; }

.form input[type=text],
.form select,
.form textarea,
.form .form-control {
  background-color: #f8f8f8 !important;
  border-radius: 4px 4px 0 0 !important;
  border-bottom: 2px solid #909090 !important;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  width: 100% !important; }

.form textarea {
  border-radius: 4px !important;
  border: 2px solid #909090 !important;
  height: 250px !important; }

.form button {
  text-align: center;
  width: 100%; }

.form select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-DownArrow%3C/title%3E%3Cg id='icon-DownArrow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Container-24px' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M20.5812309,9.28590475 C20.9386353,8.95124925 21.0812795,8.45715689 20.9545422,7.99282492 C20.8278049,7.52849295 20.4513216,7.16586015 19.9692548,7.04378544 C19.4871879,6.92171073 18.974224,7.0591071 18.6267866,7.40336289 L12,13.7863554 L5.37321157,7.40336171 C5.02577382,7.05910685 4.5128104,6.92171116 4.03074422,7.04378592 C3.54867804,7.16586068 3.17219527,7.52849293 3.04545794,7.99282427 C2.91872062,8.4571556 3.06136405,8.9512475 3.41876751,9.28590328 L11.0227778,16.6101679 C11.5625143,17.129944 12.4374856,17.129944 12.977222,16.610168 L20.5812309,9.28590475 Z' id='icon-DownArrow-Dark-Grey' fill='%2327251F'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-size: 25px;
  padding-right: 50px !important;
  width: 100%; }
  .form select:active, .form select:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;%7D.cls-2%7Bfill:%2327251f;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cg id='icon-DownArrow'%3E%3Crect id='Container-24px' class='cls-1' width='24' height='24'/%3E%3Cpath id='icon-DownArrow-Dark-Grey' class='cls-2' d='M13,7.39a1.43,1.43,0,0,0-2,0l-7.6,7.32A1.3,1.3,0,0,0,3.05,16,1.36,1.36,0,0,0,4,17a1.43,1.43,0,0,0,1.34-.36L12,10.21l6.63,6.39A1.43,1.43,0,0,0,20,17,1.36,1.36,0,0,0,21,16a1.3,1.3,0,0,0-.37-1.3Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.form .checkbox span {
  color: #909090;
  font-size: 24px;
  font-size: 1.5rem; }

.form .checkbox label {
  padding-left: 41px; }

.form .checkbox input[type='checkbox']:checked + span::after,
.form .checkbox input[type='checkbox']:checked + input[type='hidden'] + span::after {
  color: #DA291C; }

.form .checkbox .caption {
  color: #3c3c3c;
  font-size: 18px;
  font-size: 1.125rem;
  max-width: 80%;
  padding-left: 10px; }

.form .radio-inline,
.form .radio {
  margin-bottom: 20px;
  margin-right: 25px;
  padding-left: 0;
  position: inherit; }
  .form .radio-inline label,
  .form .radio label {
    font-weight: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    padding-left: 45px; }
  .form .radio-inline input[type="radio"],
  .form .radio input[type="radio"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #FFFFFF;
    margin: 0;
    width: 30px;
    height: 30px;
    border: 3px solid #909090;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    place-content: center;
    opacity: 1;
    -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
            transform: translateY(3px);
    top: -3px; }
  .form .radio-inline input[type="radio"]::before,
  .form .radio input[type="radio"]::before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms transform ease-in-out;
    transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
    -webkit-box-shadow: inset 18px 18px #DA291C;
            box-shadow: inset 18px 18px #DA291C; }
  .form .radio-inline input[type="radio"]:checked::before,
  .form .radio input[type="radio"]:checked::before {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1); }

.form .toggle-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .form .toggle-switch label:first-child {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px; }
    .form .toggle-switch label:first-child input {
      opacity: 0;
      width: 0;
      height: 0; }
    .form .toggle-switch label:first-child span {
      border-radius: 34px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #d7d8d7;
      -webkit-transition: .4s;
      transition: .4s;
      height: auto;
      width: auto; }
    .form .toggle-switch label:first-child span::before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; }
    .form .toggle-switch label:first-child span::after {
      display: none; }
    .form .toggle-switch label:first-child input:checked ~ span {
      background-color: #0075c9; }
    .form .toggle-switch label:first-child input:focus ~ span {
      -webkit-box-shadow: 0 0 1px #0075c9;
              box-shadow: 0 0 1px #0075c9; }
    .form .toggle-switch label:first-child input:checked ~ span::before {
      left: 4px;
      -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
              transform: translateX(20px); }
  .form .toggle-switch .toggle-caption {
    padding-left: 10px;
    position: relative;
    top: -2px; }
  .form .toggle-switch .caption {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem;
    left: 30px;
    padding-left: 0;
    padding-bottom: 20px;
    position: relative;
    top: 10px;
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px); }

.form .show-password {
  color: #3c3c3c;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 400;
  margin-right: 20px;
  margin-top: 10px;
  right: 0 !important;
  text-decoration: underline;
  text-transform: capitalize;
  top: 30px; }

.form .forgot-password {
  margin-top: 8px; }
  .form .forgot-password a {
    text-decoration: underline; }

.form .caption {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem; }

.form .form-group.has-success::after,
.form .form-group.has-error::after {
  display: none; }

.form-helper-text,
.form-helper-text p {
  color: #707070;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  line-height: 20px;
  line-height: 1.25rem;
  margin-top: 5px;
  margin-bottom: 3px; }

.form .form-group.has-success .input-group-addon,
.form .input-group-addon {
  background-color: #0075c9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 55px;
  z-index: 50; }
  .form .form-group.has-success .input-group-addon img,
  .form .input-group-addon img {
    height: 33px;
    position: relative;
    top: 3px;
    width: 33px; }
  .form .form-group.has-success .input-group-addon .icon-med,
  .form .input-group-addon .icon-med {
    height: 24px;
    width: 24px; }

.form .input-group-addon--transparent {
  background-color: transparent !important; }

.form-section {
  margin-top: 30px; }
  .form-section h3 {
    color: #27251f;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 34px;
    line-height: 2.125rem;
    font-family: "museo-slab", Serif; }

.form-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 25px;
  padding-bottom: 0 !important;
  padding-top: 0 !important; }
  .form-actions .btn {
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-bottom: 10px;
    text-align: center; }
    @media (min-width: 992px) {
      .form-actions .btn {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin-bottom: 0;
        margin-right: 15px;
        padding-left: 50px !important;
        padding-right: 50px !important;
        width: auto; }
        .form-actions .btn:last-child {
          margin-right: 0; } }

.form-validation-text {
  color: #707070;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  line-height: 20px;
  line-height: 1.25rem;
  margin-bottom: 10px;
  position: relative; }
  .form-validation-text:first-child {
    margin-bottom: 20px; }
  .form-validation-text i {
    color: #DA291C;
    margin: 0;
    position: absolute;
    left: 0;
    top: 3px; }
  .form-validation-text--icon {
    padding-left: 30px; }
  .form-validation-text.invalid::before, .form-validation-text.valid::before {
    display: inline-block;
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-right: 5px; }
  .form-validation-text.invalid {
    color: #DA291C; }
    .form-validation-text.invalid::before {
      content: '\F00D'; }
  .form-validation-text.valid {
    color: #009900 !important; }
    .form-validation-text.valid::before {
      content: '\F00C'; }

.form-notes ul {
  margin: 0 !important;
  padding: 0; }
  .form-notes ul li {
    margin-bottom: 12px; }

.form .radio-label-group {
  display: inline-block;
  width: -webkit-calc(100% - 50px);
  width: calc(100% - 50px); }
  .form .radio-label-group-name {
    font-weight: bold; }
    @media (max-width: 991.98px) {
      .form .radio-label-group-name {
        display: block; } }
  .form .radio-label-group-subname {
    color: #707070;
    font-weight: normal; }
    @media (min-width: 992px) {
      .form .radio-label-group-subname {
        padding-left: 16px; } }
    @media (max-width: 991.98px) {
      .form .radio-label-group-subname {
        display: block; } }
  .form .radio-label-group-body {
    display: block;
    color: #707070; }

.form-group.has-success::after {
  display: none !important; }

.form-group.has-success .form-control {
  border-color: #909090 !important; }

.dropdown--link button {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-DownArrow%3C/title%3E%3Cg id='icon-DownArrow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Container-24px' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M20.5812309,9.28590475 C20.9386353,8.95124925 21.0812795,8.45715689 20.9545422,7.99282492 C20.8278049,7.52849295 20.4513216,7.16586015 19.9692548,7.04378544 C19.4871879,6.92171073 18.974224,7.0591071 18.6267866,7.40336289 L12,13.7863554 L5.37321157,7.40336171 C5.02577382,7.05910685 4.5128104,6.92171116 4.03074422,7.04378592 C3.54867804,7.16586068 3.17219527,7.52849293 3.04545794,7.99282427 C2.91872062,8.4571556 3.06136405,8.9512475 3.41876751,9.28590328 L11.0227778,16.6101679 C11.5625143,17.129944 12.4374856,17.129944 12.977222,16.610168 L20.5812309,9.28590475 Z' id='icon-DownArrow-Dark-Grey' fill='%2327251F'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-size: 20px;
  background-color: #f8f8f8 !important;
  background-repeat: no-repeat;
  background-position: -webkit-calc(100% - 15px) center;
  background-position: calc(100% - 15px) center;
  border-radius: 4px 4px 0 0 !important;
  border-bottom: 2px solid #909090 !important;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: #27251f;
  font-weight: normal;
  font-size: 13px;
  font-size: 0.8125rem;
  padding: 10px !important;
  padding-right: 60px !important;
  text-align: left;
  width: 100%; }
  @media (min-width: 992px) {
    .dropdown--link button {
      font-size: 14px;
      font-size: 0.875rem;
      width: 475px; } }
  .dropdown--link button:active, .dropdown--link button:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;%7D.cls-2%7Bfill:%2327251f;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cg id='icon-DownArrow'%3E%3Crect id='Container-24px' class='cls-1' width='24' height='24'/%3E%3Cpath id='icon-DownArrow-Dark-Grey' class='cls-2' d='M13,7.39a1.43,1.43,0,0,0-2,0l-7.6,7.32A1.3,1.3,0,0,0,3.05,16,1.36,1.36,0,0,0,4,17a1.43,1.43,0,0,0,1.34-.36L12,10.21l6.63,6.39A1.43,1.43,0,0,0,20,17,1.36,1.36,0,0,0,21,16a1.3,1.3,0,0,0-.37-1.3Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 20px;
    background-color: #f8f8f8 !important;
    background-repeat: no-repeat;
    background-position: -webkit-calc(100% - 15px) center;
    background-position: calc(100% - 15px) center;
    color: #27251f;
    outline: none !important;
    text-align: left; }
  .dropdown--link button:hover {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-DownArrow%3C/title%3E%3Cg id='icon-DownArrow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Container-24px' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M20.5812309,9.28590475 C20.9386353,8.95124925 21.0812795,8.45715689 20.9545422,7.99282492 C20.8278049,7.52849295 20.4513216,7.16586015 19.9692548,7.04378544 C19.4871879,6.92171073 18.974224,7.0591071 18.6267866,7.40336289 L12,13.7863554 L5.37321157,7.40336171 C5.02577382,7.05910685 4.5128104,6.92171116 4.03074422,7.04378592 C3.54867804,7.16586068 3.17219527,7.52849293 3.04545794,7.99282427 C2.91872062,8.4571556 3.06136405,8.9512475 3.41876751,9.28590328 L11.0227778,16.6101679 C11.5625143,17.129944 12.4374856,17.129944 12.977222,16.610168 L20.5812309,9.28590475 Z' id='icon-DownArrow-Dark-Grey' fill='%2327251F'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: 20px;
    background-color: #f8f8f8 !important;
    background-repeat: no-repeat;
    background-position: -webkit-calc(100% - 15px) center;
    background-position: calc(100% - 15px) center;
    color: #27251f; }
  .dropdown--link button:after {
    display: none; }

.dropdown--link .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 13px;
  font-size: 0.8125rem;
  text-align: left;
  width: 100% !important; }
  @media (min-width: 992px) {
    .dropdown--link .dropdown-menu {
      font-size: 14px;
      font-size: 0.875rem;
      width: 475px !important; } }
